import React from 'react';
import { Layout, PagarSaldoContainer } from '../components';

export default function pagaSaldo() {
  return (
    <Layout cart={true}>
      <PagarSaldoContainer />
    </Layout>
  );
}
